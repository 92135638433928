<template>
  <div class="page__news-add">
    <y-form
      style="width: 100%"
      label-width="100px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetText: '取消'
      }"
      @submit="submit"
      @reset="reset"
    ></y-form>
  </div>
</template>

<script>
import { uid } from 'uid'
// import moment from 'moment'
import Upload from '@/components/upload'
// import Editor from '@/components/editor'

export default {
  name: 'BannerAdd',
  data() {
    return {
      model: {
        title: '',
        rank: '',
        // createTime: null,
        topImageFile: null,
        showImgFile: null,
        imgUrlFile: null,
        language: undefined,
        context: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '标题',
            prop: 'title'
          }
        },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '排序',
            prop: 'rank'
          }
        },
        // {
        //   type: 'date',
        //   formItemProps: {
        //     style: 'width: 460px',
        //     label: '时间',
        //     prop: 'createTime'
        //   }
        // },
        {
          type: 'radio-group',
          formItemProps: {
            style: 'width: 460px',
            label: '语言',
            prop: 'language'
          },
          attributes: {
            options: [
              {
                label: 1,
                render() {
                  return '中文'
                }
              },
              {
                label: 2,
                render() {
                  return '英文'
                }
              }
            ]
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '顶部图片',
            prop: 'topImageFile'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '内容图片',
            prop: 'showImgFile'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        },
        // {
        //   formItemProps: {
        //     style: 'width: 460px',
        //     label: '内容图片',
        //     prop: 'imgUrlFile'
        //   },
        //   render(h, prop, field, model) {
        //     return <Upload v-model={model[prop]}></Upload>
        //   }
        // },
        {
          type: 'textarea',
          formItemProps: {
            style: 'width: 460px',
            label: '内容',
            prop: 'context'
          }
        }
      ],
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }]
        // createTime: [
        //   { required: true, message: '请选择日期', trigger: 'change' }
        // ],
      }
    }
  },
  computed: {
    newsId() {
      return this.$route.params.id
    }
  },
  watch: {
    newsId: {
      handler(val) {
        if (val) this.fetchInfo()
      },
      immediate: true
    }
  },
  methods: {
    async fetchInfo() {
      const res = await this.$serve.newsInfo({
        aimid: this.newsId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.title = res.title
      this.model.rank = res.rank
      this.model.context = res.context
      // this.model.createTime = res.createTime
      this.model.language = res.language
      if (res.topImageUrl) {
        this.model.topImageFile = {
          uid: uid(),
          status: 'success',
          url: res.topImageUrl
        }
      }
      if (res.showImg) {
        this.model.showImgFile = {
          uid: uid(),
          status: 'success',
          url: res.showImg
        }
      }
      // if (res.imgUrl) {
      //   this.model.imgUrlFile = {
      //     uid: uid(),
      //     status: 'success',
      //     url: res.imgUrl
      //   }
      // }
      // if (res.contextUrl) this.fetchContent(res)
    },
    async fetchContent(record) {
      const res = await this.$serve.oss({
        urlQuery: record.contextUrl
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model.context = res
    },
    async submit({
      context,
      topImageFile,
      showImgFile,
      imgUrlFile,
      createTime,
      ...data
    }) {
      // if (createTime) {
      //   data.createTime = moment(createTime).format('YYYY-MM-DD')
      // }
      data.context = context
      if (topImageFile?.response?.data || topImageFile?.url) {
        data.topImageUrl = topImageFile.response?.data || topImageFile.url
      }
      if (showImgFile?.response?.data || showImgFile?.url) {
        data.showImg = showImgFile.response?.data || showImgFile.url
      }
      if (imgUrlFile?.response?.data || imgUrlFile?.url) {
        data.imgUrl = imgUrlFile.response?.data || imgUrlFile.url
      }
      if (this.newsId) {
        data.id = this.newsId
        this.update(data)
      } else {
        this.add(data)
      }
    },
    async add(data) {
      const res = await this.$serve.newsAdd({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('新闻添加成功')
      this.$router.push('/news')
    },
    async update(data) {
      const res = await this.$serve.newsUpdate({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('新闻修改成功')
      this.$router.push('/news')
    },
    reset() {
      this.$router.push('/news')
    }
  }
}
</script>

<style lang="scss" scoped>
.page__news-add {
  ::v-deep .upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
</style>
