<template>
  <div class="page__news">
    <y-list-page
      ref="listPageRef"
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <el-button @click="add" type="primary">添加新闻</el-button>
      </template>
    </y-list-page>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'News',
  data() {
    return {
      model: {
        keyword: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '关键词',
            prop: 'keyword'
          }
        }
      ],
      columns: [
        {
          label: '#ID',
          prop: 'id'
        },
        {
          label: '标题',
          prop: 'title',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return <div domPropsInnerHTML={v}></div>
          }
        },
        {
          label: '排序',
          prop: 'rank'
        },
        {
          label: '语言',
          prop: 'language',
          render(h, v) {
            return this.$enumsMap.languageMap[v] || '-'
          }
        },
        {
          label: '顶部图片',
          prop: 'topImageUrl',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            // return <el-avatar shape="square" size={100} src={v}></el-avatar>
            return <img src={v} style="width: 200px"></img>
          }
        },
        {
          label: '内容图片',
          prop: 'showImg',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            // return <el-avatar shape="square" size={100} src={v}></el-avatar>
            return <img src={v} style="width: 200px"></img>
          }
        },
        // {
        //   label: '内容图片',
        //   prop: 'imgUrl',
        //   render(h, v) {
        //     if (v == undefined || v === '') {
        //       return '-'
        //     }
        //     // return <el-avatar shape="square" size={100} src={v}></el-avatar>
        //     return <img src={v} style="width: 200px"></img>
        //   }
        // },
        {
          label: '内容',
          prop: 'context',
          render: (h, v, record) => {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '创建时间',
          prop: 'createTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return moment(v).format('YYYY-MM-DD')
          }
        },
        {
          label: '修改时间',
          prop: 'updateTime',
          render(h, v) {
            if (v == undefined || v === '') {
              return '-'
            }
            return v
          }
        },
        {
          label: '操作',
          render: (h, record) => {
            return (
              <div>
                <el-button vOn:click={() => this.toModify(record)} type="text">
                  修改
                </el-button>
                <el-button
                  vOn:click={() => this.deleteAction(record)}
                  type="text"
                >
                  删除
                </el-button>
              </div>
            )
          }
        }
      ]
    }
  },
  methods: {
    fetchFn({ currentPage, ...data }) {
      return this.$serve
        .newsList({
          data: {
            ...data,
            pageIndex: currentPage
          }
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return {
            ...res,
            page: res.pageIndex
          }
        })
    },
    add() {
      this.$router.push('/news/add')
    },
    toModify(record) {
      this.$router.push(`/news/update/${record.id}`)
    },
    deleteAction(record) {
      this.$confirm('确认删除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete(record)
        })
        .catch(() => {})
    },
    async delete(record) {
      const res = await this.$serve.newsDelete({
        aimid: record.id
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('删除成功')
      this.$refs.listPageRef.fetchData()
    },
    async check(record) {
      const res = await this.$serve.oss({
        urlQuery: record.contextUrl
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$alert(res, record.title, {
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        closeOnClickModal: true,
        customClass: 'page__news-msgbox'
      }).catch(() => {})
    },
    preview(record) {
      window.open(
        `http://moban004.yocsi.com/detail.html?type=news&id=${record.id}`
      )
    }
  }
}
</script>

<style lang="scss">
.page__news-msgbox {
  width: 60vw;
  .el-message-box__message {
    max-height: 80vh;
    overflow-y: auto;
  }
}
</style>
